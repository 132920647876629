import React from "react"
import {
  Footer,
  FooterLogo,
  FooterLinksHeader,
  FooterLinks,
  FooterLink,
  FooterSocial,
  FooterSocialLink,
} from "../components/Footer"
import { useContext } from "react"
import { WaterBackground } from "../components/WaterBackground"
import { useIntl } from "../util/useIntl"
import { PageContext } from "../contexts/PageContext"
import { FaTwitter, FaFacebook, FaLinkedin } from "react-icons/fa"

export const StrapiFooter = React.forwardRef((props, ref) => {
  const { data } = useContext(PageContext)
  const { intl } = useIntl()

  const logoAltText = data.strapi?.footer?.logoAltText?.text || ""
  const logo = data.strapi?.footer?.logo?.localFile?.publicURL ? (
    <img src={data.strapi.footer.logo.localFile.publicURL} alt={logoAltText} />
  ) : null

  const groups =
    data.strapi?.footer?.groups?.map(group => (
      <FooterLinks key={group.id}>
        <FooterLinksHeader>{group.heading.text}</FooterLinksHeader>
        {group.links.map(link => (
          <FooterLink key={link.id} href={intl(link.location.href).get()}>
            {link.label.text}
          </FooterLink>
        ))}
      </FooterLinks>
    )) || null

  return (
    <div className="w-full relative">
      <div className="absolute left-0 top-0 h-full w-full z-0">
        <WaterBackground color="#67b2d1" className="w-full h-full" />
      </div>
      <Footer className={"relative"} ref={ref}>
        <FooterLogo>{logo}</FooterLogo>
        <FooterSocial>
          <FooterSocialLink href={data.strapi?.footer?.twitterUrl?.text}>
            <FaTwitter />
          </FooterSocialLink>
          <FooterSocialLink href={data.strapi?.footer?.facebookUrl?.text}>
            <FaFacebook />
          </FooterSocialLink>
          <FooterSocialLink href={data.strapi.footer.linkedinUrl.text}>
            <FaLinkedin />
          </FooterSocialLink>
        </FooterSocial>
        {groups}
      </Footer>
    </div>
  )
})
